<template>
  <div id="Case">
    <h2>宜昌市青少年教育实践教育基地</h2>
    <!-- <p>Yichang Social Practice Base For Adolescents</p> -->
    <div class="case-box">
      <p>
        宜昌市青少年综合实践学校，又名“宜昌市青少年实践教育基地”。学校被评为“全国中小学生研学实践教育基地“，并被教育部认定为全国重点支持的三大研学示范性营地之一。方案通过系统化、流程化、标准化平台，为宜昌基地打造科学健全的评价体系、标准规范的接口服务、全面多元的功能应用，构建了符合未来发展的学生综合素质培养的安全环境，符合教育业务发展潮流的智能数据整合分析环境，符合国际国内发展趋势的一流信息技术应用环境。
      </p>
      <p class="title">宜昌功能应用规划布局</p>
      <img src="../../../assets/images/partnersCase/cases/case9.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      margin: 50px 0;
    }
    .title {
      font-size: 32px;
      font-weight: bold;
      color: $--color-primary;
      text-align: center;
    }
    img {
      width: 50%;
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      img {
        width: 80%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;
    p {
      font-size: 14px;
      line-height: 28px;
      margin: 30px 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 40px;
    .title {
      font-size: 28px;
    }
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
